var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"currency",style:(_vm.cssProps)},[_c('v-autocomplete',{style:({
      'width': _vm.isMobile ? '' : '85px',
      'border-radius': _vm.isMobile ? '8px !important' : '',
      'padding': _vm.isMobile ? '1px 14px' : '',
      'margin-bottom': _vm.isMobile ? '10px' : '',
      'margin-top': _vm.isMobile ? '15px' : '',
      'background-color': !_vm.isMobile ? _vm.$vuetify.theme.themes.light.markero.blue : 'white',
      'border': _vm.isMobile ? `1px solid ${_vm.$vuetify.theme.themes.light.markero.blue}` : '',
      'color': _vm.isMobile ? _vm.$vuetify.theme.themes.light.markero.blue : '',
    }),attrs:{"items":_vm.currenciesSymbol,"outlined":"","flat":"","hide-details":""},on:{"change":_vm.updateCurrencySymbol},model:{value:(_vm.selectedCurrencySymbol),callback:function ($$v) {_vm.selectedCurrencySymbol=$$v},expression:"selectedCurrencySymbol"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }